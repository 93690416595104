//================== 區分環境，配置接口地址
let host, api_url, api, mini_url,sHost,testHost, img_url;
if (process.env.NODE_ENV == "development") {  // 開發環境
    // 正式地址
    host = "https://www.gdhjxfw.com",
    sHost = "http://www.gdhjxfw.com"

    //測試地址
    testHost = "http://127.0.0.1:8080"

    // 線上
    api_url = "http://api.gdhjxfw.com"
    // mini線上
    // mini_url = "https://mini.gdhjxfw.com"
    mini_url = 'http://api.2828car.com'
    // 本地
    api = "https://api.zzships.com"

    // 圖片地址
    img_url = 'http://testimage.2828car.com'
}
if (process.env.NODE_ENV == "production") {  // 生成環境
    // 正式地址
    host = "https://www.gdhjxfw.com",
    sHost = "http://www.gdhjxfw.com"

    //測試地址
    testHost = "http://127.0.0.1:8080"
    // 線上
    api_url = "https://api.gdhjxfw.com"
    // mini線上
    // mini_url = "https://mini.gdhjxfw.com"
    mini_url = 'http://api.2828car.com'

    // 本地
    api = "https://api.zzships.com"

    // 圖片地址
    img_url = 'http://testimage.2828car.com'
}
export let HOST = host;
export let API_URL = api_url;
export let API = api;
export let MINI_URL = mini_url
export let IMG_URL = img_url

export let SHOST = sHost
export let TESTHOST = testHost