//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head1 from "./header_1.vue";
export default {
  components: {
    Head1,
  },
  data() {
    return {
      tabbarList: [
        {
          icon: require("../../assets/tabbar/home.png"),
          activeIcon: require("../../assets/tabbar/home-active.png"),
          title: "首頁",
          path: { name: "home" },
        },
        {
          icon: require("../../assets/tabbar/car.png"),
          activeIcon: require("../../assets/tabbar/car-active.png"),
          title: "買車",
          path: { name: "carList" },
        },
              {
          icon: require("../../assets/tabbar/article.png"),
          activeIcon: require("../../assets/tabbar/article-active.png"),
          title: "車行",
          path: { name: "carDealer" },
        },
        {
          icon: require("../../assets/tabbar/car.png"),
          activeIcon: require("../../assets/tabbar/car-active.png"),
          title: "零件",
          path: { name: "SpareParts" },
        },
        {
          icon: require("../../assets/tabbar/car.png"),
          activeIcon: require("../../assets/tabbar/car-active.png"),
          title: "車牌",
          path: { name: "LicensePlate" },
        },
         {
          icon: require("../../assets/tabbar/searchtype.png"),
          activeIcon: require("../../assets/tabbar/searchtype-active.png"),
          title: "查找",
          path: { name: "searchtype" },
        },
        {
          icon: require("../../assets/tabbar/house.png"),
          activeIcon: require("../../assets/tabbar/house-active.png"),
          title: "刊登",
          path: { name: "caradd" },
        },
  
        {
          icon: require("../../assets/tabbar/user.png"),
          activeIcon: require("../../assets/tabbar/user-active.png"),
          title: "我的",
          path: { name: "admin" },
        },
      ],
      tabIndex: 0,
      articleList: [
        {
          image: require("../../assets/images/carimg/aodi.jpeg"),
          name: "奧迪2020新款出售",
          brief:
            "2020年6月,奧迪性能超級充沛,店內優惠高達6.4萬  歡迎垂詢!18927560000",
          publishdate: "2022-05-20",
        },
        {
          image: require("../../assets/images/carimg/baoma.jpeg"),
          name: "寶馬2020新款出售",
          brief:
            "2020年6月,寶馬6系GT性能超級充沛,店內優惠高達6.4萬  歡迎垂詢!18927560000",
          publishdate: "2022-05-20",
        },
        {
          image: require("../../assets/images/carimg/benci.jpeg"),
          name: "奔馳2020新款出售",
          brief:
            "2020年6月，性能超級充沛,店內優惠高達6.4萬  歡迎垂詢!18927560000",
          publishdate: "2022-05-20",
        },
      ],
      display: false,
      fixdata: [
        {
          imgUrl: require("../../assets/images/fix/fix3.jpg"),
          title: "手機端",
        },
        { imgUrl: require("../../assets/images/fix/fix4.jpg"), title: "搜索" },
        {
          imgUrl: require("../../assets/images/fix/fix4.png"),
          title: "返回頂部",
        },
      ],
      navdata: ["首頁", "我要買車", "我要賣車", "車行", "汽車咨詢"],
      carconent: [
        {
          content: "品牌",
          details: ["寶馬", "奔馳", "奧迪"],
        },
        {
          content: "價格",
          details: [
            "5萬以下",
            "5-10萬",
            "10-20萬",
            "20-30萬",
            "30-50萬",
            "50-80萬",
            "5-10萬",
            "100萬以上",
          ],
        },
        {
          content: "車齡",
          details: [
            "1年以內",
            "2年內",
            "3年內",
            "3-5年內",
            "5-8年內",
            "8-10年內",
            "10年以上",
          ],
        },
        {
          content: "類型",
          details: [
            "微型車",
            "工程車",
            "貨車",
            "SUV",
            "面包車",
            "皮卡",
            "客車",
            "跑車",
            "樓車",
            "MPV",
            "豪車",
            "中大型車",
            "中型車",
            "緊湊型",
            "小型車",
            "其他車型",
          ],
        },
      ],
      imglist: ["1", "2", "3", "4", "5", "6", "7", "8"],
      carousellist: [
        { imgUrl: require("../../assets/images/banner/banner5.png") },
        { imgUrl: require("../../assets/images/banner/banner5.png") },
        { imgUrl: require("../../assets/images/banner/banner5.png") },
        { imgUrl: require("../../assets/images/banner/banner5.png") },
      ],
      articleimglist: [
        { imgUrl: require("../../assets/images/articleimg/carimg1.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg2.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg3.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg4.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg5.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg6.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg7.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg8.jpg") },
      ],
      linkAboutRowOne: [
        "投資者專區",
        "關於我們",
        "社會公益",
        "廣告合作",
        "新聞剪輯",
        "免責聲明",
        "服務條款",
        "隱私權聲明",
        "免費交換連結",
        "數字經鋿商",
      ],
      linkAboutRowTwo: [
        "澳門8591寶物交易網",
        "澳門8591寶物交易網",
        "591房屋交易(澳門)",
        "591租屋",
        "591售屋",
        "591店面",
        "591新建案",
      ],
      linkAboutRowThree: [
        "591害價登錄",
        "591新聞",
        "100室內設計",
        "8891中古車網",
        "8891新車",
        "518熊班",
        "Tasker出任務",
        "小雞上工",
        "結婚吧",
        "518找好公司",
      ],
      tipsShow: true,
      isMobile: !!window.navigator.userAgent.match(/Mobile/i),
      showTail: true,
      showQrcode: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    "$route.path": function (val) {
      console.log(val);
      if (val === "/car") {
        this.display = true;
        let tailwrapper = document.getElementById("tailwrapper");
        console.log(tailwrapper);
        tailwrapper.style.backgroundColor = "#0167b2";
      } else {
        this.display = false;
        this.$nextTick(() => {
          let tailwrapper = document.getElementById("tailwrapper");
          tailwrapper && (tailwrapper.style.backgroundColor = "#0167b2");
        });
      }
    },
    "$route.name"(val) {
      console.log(val);
      let index = this.tabbarList.findIndex((v) => {
        return v.path.name === val;
      });
      if (index === -1) {
        console.log(this.$route.matched);
        if (this.$route?.matched?.[0]?.name === "admin") {
          const TABBAR_ITEM_ORDER = 6;
          return (this.tabIndex = TABBAR_ITEM_ORDER);
        }
      }
      console.log(index);
      this.tabIndex = index;

      // let route = this.$route.matched.find((v) => {
      //   return v.name === "vehicleDetails";
      // });
      // if (route?.name === "vehicleDetails") {
      //   this.showTail = false;
      // } else {
      //   this.showTail = true;
      // }
    },
  },
  created() {
    this.$store.commit("getUserInfo");
  },
  methods: {
    clickto(item) {
      if (item.title === "返回頂部") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else if (item.title === "搜索") {
        this.$router.push("car_list");
      } else if (item.title === "手機端") {
        this.showQrcode = true;
      }
    },
    NavLeftFloatMove() {
      var xuanche = document.getElementById("xuanche");
      xuanche.style.display = "block";
    },
    NavLeftFloatout() {
      var xuanche = document.getElementById("xuanche");
      xuanche.style.display = "none";
    },
    // tabbar
    changeTabIndex(index) {
      this.tabIndex = index;
      let { path } = this.tabbarList[index];
      if (path.name === "admin") {
        console.log({ index, path });
        this.$router.push({
          ...path,
          query: {
            type:
              this.userInfo != "" && this.userInfo != null
                ? this.userInfo.userType
                : "",
          },
        });
      } else {
        console.log({ index, path });
        this.$router.push(path);
      }
    },
    toSearchPage() {
      this.$router.push({ name: "searchtype" });
    },
  },
};
