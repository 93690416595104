//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      winwidth: "",
      display: true,
      dis: false,
      navdata: ["首頁", "我要買車", "零件","車牌","刊登", "車行"],
      navActive: 0,
      loginnum: 0,
      gjzdata: ["寶馬", "奔馳", "奧迪", "瑪莎拉蒂", "路虎", "凱迪拉克", "哈弗"],
      carconent: [
        {
          content: "品牌",
          details: [
            { name: "寶馬", value: "寶馬" },
            { name: "奔馳", value: "奔馳" },
            { name: "奧迪", value: "奧迪" },
          ],
        },
        {
          content: "價格",
          details: [
            { name: "5萬以下", value: "5" },
            { name: "5-10萬", value: "10" },
            { name: "20-30萬", value: "20" },
            { name: "30-50萬", value: "30" },
            { name: "50-80萬", value: "50" },
            { name: "80-100萬", value: "80" },
            { name: "100萬以上", value: "100" },
          ],
        },
        {
          content: "車齡",
          details: [
            { name: "1年以內", value: "2022" },
            { name: "2年內", value: "2011" },
            { name: "3年內", value: "2010" },
            { name: "3-5年內", value: "2009" },
            { name: "5-8年內", value: "2008" },
            { name: "8-10年內", value: "2007" },
            { name: "10年以上", value: "2006" },
          ],
        },
        {
          content: "類型",
          details: [
            { name: "微型車", value: "微型車" },
            { name: "工程車", value: "工程車" },
            { name: "貨車", value: "貨車" },
            { name: "SUV", value: "SUV" },
            { name: "面包車", value: "面包車" },
            { name: "皮卡", value: "皮卡" },
            { name: "客車", value: "客車" },
            { name: "跑車", value: "跑車" },
            { name: "樓車", value: "樓車" },
            { name: "MPV", value: "MPV" },
            { name: "豪車", value: "豪車" },
            { name: "中大型車", value: "中大型車" },
            { name: "中型車", value: "中型車" },
            { name: "緊湊型", value: "緊湊型" },
            { name: "小型車", value: "小型車" },
            { name: "其他車型", value: "其他車型" },
          ],
        },
      ],
      imglist: ["1", "2", "3", "4", "5", "6", "7", "8"],
      carousellist: [
        { imgUrl: require("../../assets/images/banner/banner5.png") },
        { imgUrl: require("../../assets/images/banner/banner5.png") },
        { imgUrl: require("../../assets/images/banner/banner5.png") },
        { imgUrl: require("../../assets/images/banner/banner5.png") },
      ],
      articleimglist: [
        { imgUrl: require("../../assets/images/articleimg/carimg1.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg2.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg3.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg4.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg5.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg6.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg7.jpg") },
        { imgUrl: require("../../assets/images/articleimg/carimg8.jpg") },
      ],
      linkAboutRowOne: [
        "投資者專區",
        "關於我們",
        "社會公益",
        "廣告合作",
        "新聞剪輯",
        "免責聲明",
        "服務條款",
        "隱私權聲明",
        "免費交換連結",
        "數字經鋿商",
      ],
      linkAboutRowTwo: [
        "臺灣8591寶物交易網",
        "香港8591寶物交易網",
        "591房屋交易(香港)",
        "591租屋",
        "591售屋",
        "591店面",
        "591新建案",
      ],
      linkAboutRowThree: [
        "591害價登錄",
        "591新聞",
        "100室內設計",
        "8891中古車網",
        "8891新車",
        "518熊班",
        "Tasker出任務",
        "小雞上工",
        "結婚吧",
        "518找好公司",
      ],
      searchData: '',
      isMobile: !!window.navigator.userAgent.match(/Mobile/i),
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo ?? null;
    },
    isloggedin() {
      if (this.userInfo) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.winwidth = window.innerWidth;
    this.getUserInfo();
  },
  // async mounted() {
  //   window.addEventListener("click", this.navinit);
  // },
  watch: {
    "$route.path": function (val) {
      console.log(val);
      this.navActive = 5;
      this.loginnum = 0;
      if (val == "/") {
        this.navActive = 0;
      } else if (val == "/car_list") {
        this.navActive = 1;
      } else if (val == "/SpareParts") {
        this.navActive = 2;
      } else if (val == "/LicensePlate") {
        this.navActive = 3;
      } else if (val == "/caradd") {
        this.navActive = 4;
      } else if (val == "/car_dealer") {
        this.navActive = 5;
      } else if (val == "/car_zixun") {
        this.navActive = 6;
      } else if (val == "/user_login") {
        this.navActive = 100;
      } else if (val == "/user_register") {
        this.navActive = 101;
      } else {
        this.navActive = 10;
      }
    },
  },
  methods: {
    getUserInfo() {
      this.$store.commit("getUserInfo");
    },
    disnavclick(display) {
      if (this.winwidth < 500) {
        console.log(this.display);
        var header = document.getElementById("header");
        var nav = document.getElementById("nav");
        var headerright = document.getElementById("header-right");
        if (this.display == false) {
          header.style.height = "48px";
          nav.style.display = "none";
          headerright.style.display = "none";
          this.display = true;
        } else if (this.display == true) {
          header.style.height = "125px";
          nav.style.display = "block";
          headerright.style.display = "block";
          this.display = false;
        }
      }
    },
    NavLeftclick(dis) {
      var xuanche = document.getElementById("xuanche");
      if (dis == false) {
        xuanche.style.display = "block";
        this.dis = true;
      } else if (dis == true) {
        xuanche.style.display = "none";
        this.dis = false;
      }
    },
    toSearchPage(item, itemc) {
      let data = {};
      switch (item.content) {
        case "品牌":
          data.name = itemc.value;
          break;
        case '價格': 
          data.xsjg = itemc.value;
          break;
        case '車齡': 
          data.fzrq = itemc.value;
          break;
        case '類型': 
          data.cx = itemc.value;
          break;
      }

      this.$router.push({
        name: "carList",
        query: { q: { ...data } },
      });
    },
    handleSearch() {
      this.$router.push({name: 'carList', query: {q: {name: this.searchData}}})
    },
    toCarListPage(item) {
      this.$router.push({name: 'carList', query: {q: {name: item}}})
    },
    clickto(index) {
      console.log(index);
      // if (index == 1) {
      //   this.$router.push("/car_list");
      // } else if (index == 0) {
      //   this.$router.push("/");
      // } else if (index == 3) {
      //   this.$router.push("/car_dealer");
      // } else if (index == 4) {
      //   this.$router.push("/car_zixun");
      // } else if (index == 2) {
      //   this.$router.push("/caradd");
      // }
      if (index == 0) {
        this.$router.push("/");
      } else if (index == 1) {
        this.$router.push("/car_list");
      } else if (index == 2) {
        this.$router.push("/SpareParts");
      } else if (index == 3) {
        this.$router.push("/LicensePlate");
      } else if (index == 4) {
        this.$router.push("/caradd");
      } else if (index == 5) {
        this.$router.push("/car_dealer");
      } else if (index == 6) {
        this.$router.push("/car_zixun");
      }
      this.display == false;
      this.disnavclick();
    },
    userlogin(num) {
      this.$router.push("/user_login");
      this.display == false;
      this.disnavclick();
    },
    userregister(num) {
      this.$router.push("/user_register");
      this.display == false;
      this.disnavclick();
    },
    navinit() {
      console.log(window.location.pathname);
      // if (index == 1) {
      //   this.$router.push("/car_list");
      // } else if (index == 0) {
      //   this.$router.push("/");
      // } else if (index == 3) {
      //   this.$router.push("/car_dealer");
      // } else if (index == 4) {
      //   this.$router.push("/car_zixun");
      // } else if (index == 2) {
      //   this.$router.push("/caradd");
      // }
    },
    NavLeftFloatMove() {
      var xuanche = document.getElementById("xuanche");
      xuanche.style.display = "block";
    },
    NavLeftFloatout() {
      var xuanche = document.getElementById("xuanche");
      xuanche.style.display = "none";
    },
    toMySpace() {
      this.$router.push({
        name: "admin",
        query: { type: this.userInfo.userType },
      });
    },
  },
};
