//
//
//
//
//
//

import Head from './components/layouts/header.vue'
export default {
  components:{
    Head
  },
}
